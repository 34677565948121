<template>
  <referentiel-list
    :listTitle="'Liste des organismes'"
    :referentielName="referentielName"
    :tableFields="tableFields"
    :emptyReferentielItem="emptyReferentielItem"
  >
    <template #formContent="{ handleInput, editableItem }">
      <div class="row">
        <div class="col-sm-12 field mb-4">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <h5>Agent source</h5>
            <AsyncSearchInput
              v-model="editableItem.agentSource"
              :multiple="false"
              :queryUrl="'agents'"
              :optionLabel="'nomPrenom'"
              :searchOptions="['nomPrenom']"
              placeholder="Rechercher un agent"
            />
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </ValidationProvider>
        </div>

        <div class="col-sm-12 field mb-4">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <h5>Agent destination</h5>
            <AsyncSearchInput
              v-model="editableItem.agentDestination"
              :multiple="false"
              :queryUrl="'agents'"
              :optionLabel="'nomPrenom'"
              :searchOptions="['nomPrenom']"
              placeholder="Rechercher un agent"
            />
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
              err
            }}</span>
          </ValidationProvider>
        </div>

        <div class="col-sm-12">
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="form- mb-4">
              <label for="referenceCommentaire">Transfert à exclure</label>

              <AsyncSearchInput
                v-model="editableItem.transfertPortefeuilleExclu"
                :multiple="false"
                :queryUrl="'transfertPortefeuilles'"
                :optionLabel="'referenceCommentaire'"
                :searchOptions="['referenceCommentaire']"
                placeholder="Rechercher par référence ou commentaire"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-sm-12">
          <ValidationProvider rules="required" v-slot="{ errors }">
            <div class="form-floating mb-3">
              <textarea
                :value="editableItem.commentaire"
                @input="handleInput"
                type="text"
                class="form-control"
                id="commentaire"
                name="commentaire"
                placeholder="Commentaire"
                rows="30"
                style="height: 200px"
              />
              <label for="commentaire">Commentaire</label>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
      </div>
    </template>
  </referentiel-list>
</template>

<script>
import { mapGetters } from "vuex";
import ReferentielList from "../../../components/common/referentiel/ReferentielList.vue";
import Multiselect from "vue-multiselect";
import { mapActions } from "vuex";
import AsyncSearchInput from "@/components/common/AsyncSearchInput.vue";

export default {
  components: {
    AsyncSearchInput,
    ReferentielList,
    Multiselect,
  },
  computed: {
    ...mapGetters({
      organismes: "organisme/organismes",
      roles: "role/roles",
    }),
    referentielName() {
      return "transfertPortefeuille";
    },
    emptyReferentielItem() {
      return {
        intitule: null,
        description: null,
        parent: null,
        roles: [],
      };
    },
    tableFields() {
      return [
        "#",
        "index",
        { key: "reference", label: "Reference" },
        { key: "agentSource.nomPrenom", label: "Ag. Source" },
        { key: "agentDestination.nomPrenom", label: "Ag. Dest" },
        { key: "createdDate", label: "Date", format: (val) => 1 },
        "actions",
      ];
    },
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    ...mapActions({
      fetchRoles: "role/fetchRoles",
    }),
  },
};
</script>

<style></style>
